import React from 'react';

export const PrivacyPage: React.FC = () => {
 return (
   <div className="p-5 mx-auto container pt-12 lg:pt-20 pb-4 relative flex flex-col gap-y-6 bg-primary-50">
     <div className="md:text-lg max-w-xl pt-6">
       <h3 className="text-2xl font-bold mb-4">Privacy Policy</h3>

       <p>
         Protecting your private information is our priority. This Statement of Privacy applies to talkScaled, and
         talkScaled Inc. and governs data collection and usage. For the purposes of this Privacy Policy, unless
         otherwise noted, all references to talkScaled Include{' '}
         <a href="http://www.talkScaled.com/" target="_blank" rel="noopener noreferrer">
           www.talkScaled.com
         </a>{' '}
         and talkScaled. The talkScaled application is a user application phone service. By using the talkScaled application, you
         consent to the data practices described in this statement.
       </p>

       <h3 className="text-xl font-bold mt-8 mb-4">Collection of your Personal Information</h3>
       <p>
         We collect several different types of data for various purposes, primarily to provide and improve our
         Services. These data include:
       </p>

       <ol className="list-decimal pl-6">
         <li>
           <h3 className="font-bold">Personal Data</h3>
           <p>
             In order to better provide you with products and services offered, talkScaled may ask you to provide
             certain personally identifiable information that can be used to contact or identify you ("
             <strong>Personal Data</strong>"). These may include, but not limited to:
           </p>
           <ul className="list-disc pl-6">
             <li>Email address.</li>
             <li>First name and last name.</li>
             <li>Phone number.</li>
             <li>Address, State, Province, ZIP/Postal code, City.</li>
             <li>Cookies and Usage Data</li>
           </ul>
           <p>
             Please note that we do not collect any personal information about you unless you voluntarily provide it to
             us. To this end, we will use your information for, but not limited to, communicating with you in relation
             to services and/or products you have requested from us, and sending you newsletters, marketing or
             promotional materials and other information that may be of interest to you. You may opt out of receiving
             any, or all, of these communications from us by following the unsubscribe link.

             We will NEVER sell the data of your business's or your client's phone calls. We will not use or store your phone call data in any way without your consent.
           </p>
         </li>

         <li>
           <h3 className="font-bold">Usage Data</h3>
           <p>
             Additionally, whether you visit our service or use a mobile device to access it, we might gather data from
             your browser ("<strong>Usage Data</strong>").
           </p>
           <p>
             This Usage Data may contain information like the Internet Protocol address (IP address) of your computer,
             the type and version of your browser, the pages you visit on our service, the time and date of your visit,
             the amount of time you spend on those pages, unique device identifiers, and other diagnostic data.
           </p>
           <p>
             The information that may be included in this usage data when you use a mobile device to access the service
             includes the kind of mobile device you use, its unique ID, its IP address, its operating system, the kind
             of mobile Internet browser you use, and other diagnostic data.
           </p>
         </li>

         <li>
           <h3 className="font-bold">Location Data</h3>
           <p>
             If you grant us permission to do so, we may use and keep track of your location data ("
             <strong>Location Data</strong>"). We utilize your information to offer features, enhance, and personalize
             our service.
           </p>
           <p>
             Whenever you use our Service, you have the option to enable or disable location services using your
             device's settings.
           </p>
         </li>

         <li>
           <h3 className="font-bold">Tracking & Cookies Data</h3>
           <p>
             We use cookies and similar tracking technologies to track the activity on our Service and we hold certain
             information.
           </p>
           <p>
             Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are
             sent to your browser from a website and stored on your device. Other tracking technologies are also used
             such as beacons, tags, and scripts to collect and track information and to improve and analyze our
             Service.
           </p>
           <p>
             You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However,
             if you do not accept cookies, you may not be able to use some portions of our Service.
           </p>
           <p>Examples of Cookies we use:</p>
           <ul className="list-disc pl-6">
             <li>
               <strong>Session Cookies:</strong> We use Session Cookies to operate our Service.
             </li>
             <li>
               <strong>Preference Cookies:</strong> We use Preference Cookies to remember your preferences and various
               settings.
             </li>
             <li>
               <strong>Security Cookies:</strong> We use Security Cookies for security purposes.
             </li>
             <li>
               <strong>Advertising Cookies:</strong> Advertising Cookies are used to serve you with advertisements
               that may be relevant to you and your interests.
             </li>
           </ul>
         </li>

         <li>
           <h3 className="font-bold">Use of Data</h3>
           <p>talkScaled Inc. uses the collected data to:</p>
           <ul className="list-disc pl-6">
             <li>fulfill the purpose for which you provide it;</li>
             <li>provide and maintain our Service;</li>
             <li>notify you about changes to our Service;</li>
             <li>allow you to participate in interactive features of our Service when you choose to do so;</li>
             <li>provide customer support;</li>
             <li>gather analysis or valuable information so that we can improve our Service;</li>
             <li>monitor the usage of our Service;</li>
             <li>detect, prevent, and address technical issues;</li>
             <li>
               carry out our obligations and enforce our rights arising from any contracts entered into between you and
               us, including for billing and collection;
             </li>
             <li>
               provide you with notices about your account and/or subscription, including expiration and renewal
               notices, email-instructions, etc.;
             </li>
             <li>
               provide you with news, special offers, and general information about other goods, services, and events
               which we offer that are similar to those that you have already purchased or inquired about unless you
               have opted not to receive such information;
             </li>
             <li>in any other way we may describe when you provide the information;</li>
             <li>for any other purpose with your consent.</li>
           </ul>
         </li>

         <li>
           <h3 className="font-bold">Retention of Data</h3>
           <p>
             We will retain your Personal Data only for as long as is necessary for the purposes set out in this
             Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal
             obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
             disputes, and enforce our legal agreements and policies.
           </p>
           <p>
             We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
             shorter period, except when this data is used to strengthen the security or to improve the functionality
             of our Service, or we are legally obligated to retain this data for longer time periods.
           </p>
         </li>

         <li>
           <h3 className="font-bold">Transfer of Data</h3>
           <p>
             Your information, including Personal Data, may be transferred to – and maintained on – computers located
             outside of your state, province, country, or other governmental jurisdiction where the data protection
             laws may differ from those of your jurisdiction.
           </p>
           <p>
 Please be aware that if you choose to give us information while you are not in the US, the data—including Personal Data—will be transferred to and processed in the US. 
 Your agreement to the transfer is shown by your acceptance of this Privacy Policy and your submission of such information.
</p>
<p>
 talkScaled Inc. will take all reasonably required actions to guarantee that your data is handled securely and in compliance with this privacy policy. Your personal data will not be transferred to another organization or nation unless sufficient safeguards, such as those pertaining to the security of your data and other personal information, are in place.
</p>
         </li>

         <li>
           <h3 className="font-bold">Disclosure of Data</h3>
           <p>We may disclose personal information that we collect, or you provide:</p>
           <ul className="list-disc pl-6">
             <li>if required to do so by law or in response to valid requests by public authorities.</li>
             <li>
               where we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Personal Data
               may be transferred.
             </li>
             <li>
               <strong>We may disclose your information also:</strong>
               <ul className="list-disc pl-6">
                 <li>to our subsidiaries and affiliates;</li>
                 <li>to fulfill the purpose for which you provide it;</li>
                 <li>for any other purpose disclosed by us when you provide the information;</li>
                 <li>with your consent in any other cases;</li>
                 <li>
                   if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
                   the Company, our customers, or others.
                 </li>
               </ul>
             </li>
           </ul>
         </li>

         <li>
           <h3 className="font-bold">Security of Data</h3>
           <p>
             While recognizing the importance of securing your data, we understand that no method of transmission over
             the Internet or method of electronic storage is 100% secure. Nevertheless, we will strive to use
             commercially acceptable means to protect your Personal Data, but we cannot guarantee its absolute security.
           </p>
         </li>

         <li>
           <h3 className="font-bold">E-mail Communications</h3>
           <p>
             From time to time, talkScaled may contact you via email for the purpose of providing announcements,
             promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to
             improve our Services, we may receive a notification when you open an email from talkScaled or click on a
             link therein.
           </p>
           <p>
             If you would like to stop receiving marketing or promotional communications via email from talkScaled, you
             may opt out of such communications by clicking "unsubscribe" and typing "STOP".
           </p>
         </li>
       </ol>

       <h3 className="text-xl font-bold mt-8 mb-4">Your Data Protection Rights Under various Laws.</h3>

       <ol className="list-decimal pl-6" start={1}>
         <li>
           <h3 className="font-bold">General Data Protection Regulation (GDPR)</h3>
           <p>
             If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data
             protection rights, covered by GDPR (See more at https://eur-lex.europa.eu/eli/reg/2016/679/oj).
           </p>
           <p>
             We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal
             Data, and if you wish to be informed on what Personal Data we hold about you and you want same to be
             removed from our systems, please text us at 5713366003
           </p>
           <p>In certain circumstances, you have the following data protection rights:</p>
           <ul className="list-disc pl-6">
             <li>the right to access, update or to delete your data on our site;</li>
             <li>the right to rectify your information if that information is inaccurate or incomplete;</li>
             <li>the right to object to the processing of your Personal Data;</li>
             <li>the right to request that the processing of your personal information is restricted;</li>
             <li>
               the right to request and be provided with a copy of your Personal Data in a structured, machine-readable
               and commonly used format;
             </li>
             <li>
               the right to withdraw consent at any time where your consent is required to process any of your personal
               information;
             </li>
           </ul>
           <p>
             Please note that we may ask you to verify your identity before responding to such requests as we may not
             be able to provide Service without some necessary data.
           </p>
           <p>
             You have the right to complain to a Data Protection Authority about our collection and use of your
             Personal Data. For more information, please contact your local data protection authority in the European
             Economic Area (EEA).
           </p>
         </li>

         <li>
           <h3 className="font-bold">California Privacy Protection Act (CalOPPA)</h3>
           <p>According to CalOPPA, we agree to the following:</p>
           <ul className="list-disc pl-6">
             <li>users can visit our site anonymously;</li>
             <li>
               our Privacy Policy link includes the word "Privacy", and can easily be found on the page specified above
               on the home page of our website;
             </li>
             <li>users will be notified of any privacy policy changes on our Privacy Policy Page;</li>
             <li>users can change their personal information by texting us at 5713366003</li>
           </ul>
           <p>Our Policy on "Do Not Track" Signals:</p>
           <p>
             When a "Do Not Track" browser feature is enabled, we respect the signals and don't track, install
             cookies, or employ advertising. You can tell websites that you do not want to be tracked by using the Do
             Not Track option on your web browser. Go to your browser's Preferences or Settings page to enable or
             disable Do Not Track.
           </p>
         </li>

         <li>
           <h3 className="font-bold">California Consumer Privacy Act (CCPA)</h3>
           <p>If you are a California resident, you are entitled to learn what data we collect about you, ask to delete your data and not to sell (share) it. To exercise your data protection rights, you can make certain requests and ask us:</p>
           <p><strong>What personal information we have about you</strong>. If you make this request, we will return to you:</p>
           <ul className="list-disc pl-6">
             <li>The categories of personal information we have collected about you.</li>
             <li>The categories of sources from which we collect your personal information.</li>
             <li>The business or commercial purpose for collecting or selling your personal information.</li>
             <li>The categories of third parties with whom we share personal information.</li>
             <li>The specific pieces of personal information we have collected about you.</li>
             <li>A list of categories of personal information that we have sold, along with the category of any other company we sold it to. If we have not sold your personal information, we will inform you of that fact.</li>
             <li>A list of categories of personal information that we have disclosed for a business purpose, along with the category of any other company we shared it with.</li>
           </ul>
           <p>Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.</p>
           <p><strong>To delete your personal information</strong>. If you make this request, we shall remove from our records any personal information we have on file as of the request date and instruct any service providers to follow suit. De-identification of the information can sometimes be used to achieve deletion. However, you might not be able to utilize some features that depend on your personal information to work if you decide to remove it.</p>
           <p><strong>To stop selling your personal information</strong>. Your personal information is never sold to other parties for profit. However, in certain situations,a non-monetary transfer of personal data to a third party or another member of our family of businesses can be regarded by California law as a "sale."
</p>
<p>
 We shall cease transferring your personal data if you request that we no longer sell it. If you live in California and would like to request that your personal information not be sold, send an email to founders@re-tellai.com with the subject "Do Not Sell My Personal Information".
</p>
<p>
 Please be aware that requesting that we remove or cease selling your data may have an effect on how you interact with us and may prevent you from taking advantage of membership services or certain programs that depend on the use of your personal data. However, we would never treat you unfairly just because you're exercising your rights.
</p>
<p>To exercise your California data protection rights described above, please send your request(s) to 5713366003</p>
<p>
 Your data protection rights, described above, are covered by the CCPA. To find out more, visit the official California Legislative Information website. The CCPA took effect on 01/01/2020.
</p>
         </li>
       </ol>

       <h2 className="text-xl font-bold mt-8 mb-4">Service Providers</h2>
       <p>
         <strong>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</strong>
       </p>
       <p>
         These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
       </p>
       <p>
         You can request an up-to-date list of third-party Service Providers by texting us at 5713366003. We provide a non-comprehensive list of our Service Providers below.
       </p>

       <ol className="list-decimal pl-6" start={10}>
         <li>
           <h3 className="font-bold">Analytics</h3>
           <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
           <ul className="list-disc pl-6">
             <li>
               <strong>Google Analytics</strong>: Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a>
             </li>
             <li>
               <strong>Cloudflare Analytics</strong>: Cloudflare analytics is a web analytics service operated by Cloudflare Inc. Read the Privacy Policy here: <a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener noreferrer">https://www.cloudflare.com/privacypolicy/</a>
             </li>
             <li>
               <strong>Segment.io</strong>: Segment.io is a web traffic analysis tool. Read the Privacy Policy for Segment.io here: <a href="https://segment.com/legal/privacy/" target="_blank" rel="noopener noreferrer">https://segment.com/legal/privacy/</a>.
             </li>
             <li>
               <strong>Mixpanel</strong>: Mixpanel is provided by Mixpanel Inc. You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: <a href="https://mixpanel.com/optout/" target="_blank" rel="noopener noreferrer">https://mixpanel.com/optout/</a>
             </li>
             <li>
               <strong>PostHog</strong>: For more information about PostHog, please visit their Privacy Policy: <a href="https://posthog.com/privacy" target="_blank" rel="noopener noreferrer">https://posthog.com/privacy</a>
             </li>
           </ul>
         </li>
         <li>
           <h3 className="font-bold">CI/CD tools</h3>
           <ul className="list-disc pl-6">
             <li>
               <strong>GitHub</strong>: GitHub is provided by GitHub, Inc. GitHub is a development platform to host and review code, manage projects, and build software. For more information on what data GitHub collects and how the protection of the data is ensured, please visit GitHub Privacy Policy page: <a href="https://help.github.com/en/articles/github-privacy-statement" target="_blank" rel="noopener noreferrer">https://help.github.com/en/articles/github-privacy-statement</a>.
             </li>
           </ul>
         </li>
       </ol>

       <h2 className="text-xl font-bold mt-8 mb-4">Payments</h2>
       <p>We provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
       <p>The payment processors we work with are:</p>
       <ul className="list-disc pl-6">
         <li>
           <strong>Stripe:</strong> Their Privacy Policy can be viewed at: <a href="https://stripe.com/us/privacy" target="_blank" rel="noopener noreferrer">https://stripe.com/us/privacy</a>
         </li>
       </ul>

       <h2 className="text-xl font-bold mt-8 mb-4">Use of your Personal Information</h2>
       <p>
         talkScaled collects and uses your personal information to operate and deliver the services you have requested. talkScaled may also use your personally identifiable information to inform you of other products or services available from talkScaled and its affiliates.
       </p>

       <h2 className="text-xl font-bold mt-8 mb-4">Sharing Information with Third Parties</h2>
       <p>
         talkScaled does not sell, rent, or lease its customer lists to third parties. talkScaled may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to talkScaled, and they are required to maintain the confidentiality of your information.
       </p>
       <p>
         talkScaled may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on talkScaled or the site; (b) protect and defend the rights or property of talkScaled; and/or (c) act under exigent circumstances to protect the personal safety of users of talkScaled, or the public.
       </p>

       <h2 className="text-xl font-bold mt-8 mb-4">Right to Deletion</h2>
       <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>
       <ul className="list-disc pl-6">
         <li>Delete your personal information from our records; and</li>
         <li>Direct any service providers to delete your personal information from their records.</li>
       </ul>
       <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</p>
       <ul className="list-disc pl-6">
         <li>
           Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;
         </li>
         <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;</li>
         <li>Debug to identify and repair errors that impair existing intended functionality;</li>
         <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</li>
         <li>Comply with the California Electronic Communications Privacy Act;</li>
<li>
 Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;
</li>
<li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</li>
<li>Comply with an existing legal obligation; or</li>
<li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
</ul>

<h2 className="text-xl font-bold mt-8 mb-4">Children Under Thirteen</h2>
<p>
 talkScaled does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this application.
</p>

<h2 className="text-xl font-bold mt-8 mb-4">Changes to this Statement</h2>
<p>
 talkScaled reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information. Your continued use of the application and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
</p>

<h2 className="text-xl font-bold mt-8 mb-4">Contact Information</h2>
<p>
 talkScaled welcomes your questions or comments regarding this Statement of Privacy. If you believe that talkScaled has not adhered to this Statement, please contact talkScaled at:
</p>
  <p className='underline'><a href="mailto:hello@talkscaled.com">Email: hello@talkscaled.com</a></p>
  <p>Effective as of April 2, 2024</p>
  <p className='underline'><a href="talkscaled.com">talkscaled.com</a></p>


     </div>
   </div>
 );
};
         



         