import React, { useState } from 'react';
import { ReactComponent as LogoSVG } from './../../../Assets/logoIcon.svg';

export const Footer: React.FC = () => {
    return (
        <footer className="w-full">
            <hr className="border-t-2" />
            <div className="flex flex-wrap justify-between items-center px-5 py-8">
                <div className="flex items-center">
                    <LogoSVG className="hidden sm:block h-8 fill-current mr-6" />

                    <a className="underline px-4"  href="/terms">
                        Terms
                    </a>
                    <a className="underline px-4" href="/privacy">
                        Privacy
                    </a>
                </div>
                <span className="text-md px-4 pt-3 sm:pt-0">
                    {new Date().getFullYear()} talkScaled ©   Made in Virginia
                </span>
            </div>
        </footer>
    );
};
